<template>
    <div id="customer-portal-page" class="w-full">
        <div class="d-flex align-center justify-space-between">
            <v-breadcrumbs :items="breadcrumbList" class="pl-0 config-breadcrumbs">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item @click.native="!item.disabled && $router.push(item.href)" class="primary--text"
                        :class="{'secondary--text': item.disabled}">
                            {{ item.text }}
                    </v-breadcrumbs-item>
                </template>
                <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                </template>
            </v-breadcrumbs>
            <div>
                <v-tooltip bottom content-class="car-architecture-tooltip" attach :disabled="scanLoading || metaArchLoading || refreshLoading">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs"  :style="{backgroundColor: agentModel.primary_color, color: agentModel.text_color}" v-on="on" class="mx-2 mb-1" v-if="scanId" small icon dark
                            @click="!scanLoading && !metaArchLoading && !refreshLoading && refreshScan()">
                                <v-icon dark size="20" v-if="!scanLoading && !metaArchLoading && !refreshLoading">mdi-sync</v-icon>
                                <v-progress-circular v-else size="15" :width="3" :style="`color: ${agentModel.text_color}`" indeterminate></v-progress-circular>
                        </v-btn>
                    </template>
                    <span>Refresh</span>
                </v-tooltip>
                <template v-if="agentId && !scanId">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" class="mr-2" :color="agentModel.primary_color" :style="`color: ${agentModel.text_color}`"
                                @click="createManual" :disabled="loading" v-on="on">
                                <v-progress-circular v-if="loading"  class="me-2" indeterminate size="20" :width="2" :style="`color: ${agentModel.text_color}`"></v-progress-circular>
                                <span>{{ loading ? 'Creating...' : 'Create Scan' }}</span>
                            </v-btn>
                        </template>
                        <span>Create manual scan</span>
                    </v-tooltip>
                </template>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" class="mr-2" :color="agentModel.primary_color" :style="`color: ${agentModel.text_color}`"
                            @click="openValidation = true" v-on="on">
                            Go to Admin
                        </v-btn>
                    </template>
                    <span>Back to scan page</span>
                </v-tooltip>
            </div>
        </div>
        <scans-list v-if="agentId && !scanId" :refresh="refreshPage" @refreshed="refreshPage=false" :model="agentModel"/>
        <scan-form v-if="agentId && scanId" :refresh="refreshPage" @refreshed="refreshPage=false" :color="agentModel.primary_color" :textColor="agentModel.text_color"/>
        <common-modal :open="openValidation" :width="'30%'" @close="openValidation = false" >
            <v-card class="widh-full">
                <v-card-title>Code Validation</v-card-title>
                <v-form lazy-validation @submit.prevent="validationSubmit">
                    <v-card-text>
                        <v-row class="ma-0 pa-0">
                            <v-col cols="12">
                                <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Pincode</v-subheader>
                                <v-text-field  ref="pincode" v-model="model.pincode" :error-messages="$helpers.errorMsg('name', $v.model.pincode, 'Pincode')"
                                    placeholder="Pincode" outlined @input="$v.model.pincode.$touch()" required/>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="pb-6 px-4">
                        <v-spacer></v-spacer>
                        <v-btn type="submit" :color="agentModel.primary_color" :style="`color: ${agentModel.text_color}`" :disabled="loading">Submit</v-btn>
                        <v-btn type="button" :color="agentModel.primary_color" :disabled="loading" outlined @click="openValidation = false">Cancel</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </common-modal>
    </div>
  </template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {CommonModal} from "@/components/common";
import { required } from "vuelidate/lib/validators";
import moment from "moment-timezone";

export default {
    name: "Scans",
    metaInfo: {
      title: "Scans",
    },
    components: {
        CommonModal,
        scansList: () => import("./components/ScansList"),
        scanForm: () => import("../scans/ScanForm"),
    },
    validations() {
       return {
            model: {
                pincode: { required},
            }
        }
    },
    data: (vm) => ({
        agentId: vm.$route.params.agentId,
        scanId: vm.$route.params.scanId || null,
        breadcrumbList: [{
            text: 'Customer Portal',
            disabled: true,
            href: `/customer-portal/${vm.$route.params.agentId}`,
        }],
        refreshLoading: false,
        refreshPage: false,
        agentModel: {},
        openValidation: false,
        model: {},
        loading: false
    }),
    computed: {
      ...mapGetters(['scanLoading', 'metaArchLoading'])
    },
    watch: {
      $route(newVal) {
        this.agentId = newVal.params.agentId;
        this.scanId =  newVal.params.scanId || null;
        this.setBreadCrumb();
      }
    },
    created() {
      this.setBreadCrumb();
      if(this.agentId) {
        this.getAgentModel();
      }
    },
    methods: {
        ...mapActions(['setScanRefresh', 'createScan', 'getSelectedAgent', 'validatePincode', 'changeHideHeaderIcon', 'updateVerticalSidebarDrawer']),

        setBreadCrumb() {
            this.updateVerticalSidebarDrawer(false);
            this.changeHideHeaderIcon(true);
            if(this.scanId) {
                this.breadcrumbList[0].disabled = false;
                this.breadcrumbList.push({
                    text: 'Scan Detail',
                    disabled: true,
                    href: `/customer-portal/${this.agentId}/${this.scanId}`,
                })
            } else {
                this.breadcrumbList = [{
                    text: 'Customer Portal',
                    disabled: true,
                    href: `/customer-portal/${this.agentId}`,
                }];
            }
        },
        getAgentModel() {
            this.getSelectedAgent(this.agentId).then(response => {
                this.agentModel = response || {};
                this.agentModel.primary_color = this.agentModel.primary_color || 'primary';
                this.agentModel.text_color = this.agentModel.text_color || '#000000';
                this.agentModel.default_client_id = this.agentModel.default_client_id || null;
                this.agentModel.default_client_name = this.agentModel.default_client_name || null;
            });
        },
        createManual() {
            this.loading = true;
            this.createScan({
                agent_id: this.agentId,
                client_id: this.agentModel.default_client_id,
                client_name: this.agentModel.default_client_name,
                startTime: moment(),
                type: 'tire',
                status: 'uploaded'
            }).then(res => {
                this.$router.push(`/customer-portal/${this.agentId}/${res.id}`);
                this.loading = false;
            }).catch(()=>{
                this.loading = false;
            });
        },
        refreshScan() {
            this.refreshLoading = true;
            this.setScanRefresh(this.scanId).then(data => {
                this.refreshPage = true;
                this.refreshLoading = false;
            }).catch(err => this.refreshLoading = false);
        },
        validationSubmit() {
            this.loading = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$helpers.focusErrorElement(this.$v.model, this.$refs);
                this.loading = false;
                return;
            }
            this.loading = false;
            this.validatePincode({ id: this.agentId, data: { pin: this.model.pincode } }).then((response) => {
                if (response) {
                    this.$router.push('/scans');
                } else {
                    this.model.pincode = '';
                    this.model.pincode = '';
                    this.$store.commit("snackbar/SHOW_MESSAGE", {
                        text: "Enter Valid Pincode!.",
                        color: "error",
                    });
                    return;
                }
            }).catch(() => this.loading = false);
        }
    }
};
</script>
